// Configuration
export const config = {
    clefsIGN: 'essentiels',
    coucheIGN: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
    attributions: {
        'PPR': '<a href=\'https://www.brgm.fr\' target="_blank">BRGM</a>',
        'SISMICITE': '<a href=\'https://www.brgm.fr\' target="_blank">BRGM</a>',
        'RADON': '<a href=\'https://www.irsn.fr\' target="_blank">IRSN</a>',
        'PEB': '<a href=\'https://www.ecologie.gouv.fr/direction-generale-laviation-civile-dgac\' target="_blank">DGAC</a>',
        'ARGILE': '<a href=\'https://www.brgm.fr\' target="_blank">BRGM</a>',
        'CANALISATIONS': '<a href=\'https://www.brgm.fr\' target="_blank">BRGM</a>', 
        'POLLUTION_NON_REG': '<a href=\'https://www.brgm.fr\'>BRGM</a> | <a href=\'https://www.ecologie.gouv.fr\'>MTE</a> | DREAL/DRIEE',
        'OLD': '<a href=\'https://www.brgm.fr\' target="_blank">BRGM</a>',
    },
    couchesRisques: {
        argile: {
            layer: 'risq_argiles',
            serveurs: {'recette':'https://mapsrefrec.brgm.fr/wxs/georisques/errial_risques?','prod':'https://mapsref.brgm.fr/wxs/georisques/errial_risques?'}
        },
        radon: {
            layer: 'risq_radon',
            serveurs: {'recette':'https://mapsrefrec.brgm.fr/wxs/georisques/errial_risques?','prod':'https://mapsref.brgm.fr/wxs/georisques/errial_risques?'}
        },
        sismicite: {
            layer: 'risq_zonage_sismique',
            serveurs: {'recette':'https://mapsrefrec.brgm.fr/wxs/georisques/errial_risques?','prod':'https://mapsref.brgm.fr/wxs/georisques/errial_risques?'}
        },
        peb: {
            layer: 'risq_peb',
            serveurs: {'recette':'https://mapsrefrec.brgm.fr/wxs/georisques/errial_risques?','prod':'https://mapsref.brgm.fr/wxs/georisques/errial_risques?'}
        },
        canalisations: {
            layer: 'CANALISATIONS',
            serveurs: {'recette':'https://mapsrefrec.brgm.fr/wxs/georisques/errial_risques?','prod':'https://mapsref.brgm.fr/wxs/georisques/errial_risques?'}
        },
        ppr_georisques: {
            layer: 'ppr_georisques',
            serveurs: {'recette':'https://mapsrefrec.brgm.fr/wxs/georisques/errial_risques?','prod':'https://mapsref.brgm.fr/wxs/georisques/errial_risques?'}
        },    
        ppr_gpu: {
            layer: 'ppr_gpu',
            serveurs: {'recette':'https://mapsrefrec.brgm.fr/wxs/georisques/errial_risques?','prod':'https://mapsref.brgm.fr/wxs/georisques/errial_risques?'}
        },
        tri: {
            layer: 'tri_alea',
            serveurs: {'recette':'https://mapsrefrec.brgm.fr/wxs/georisques/errial_risques?','prod':'https://mapsref.brgm.fr/wxs/georisques/errial_risques?'}
        },
        old: {
            layer: 'ZONAGE_OLD',
            serveurs: {'recette':'https://mapsref.brgm.fr/wxs/georisques/errial_risques?','prod':'https://mapsref.brgm.fr/wxs/georisques/errial_risques?'}
        },
    },
    messages: {
        errorApi: "Le service de recherche n'est pas disponible pour le moment, veuillez réessayer plus tard.",
        errorPdf: "Le service de téléchargement de l'ERRIAL n'est pas disponible pour le moment, veuillez réessayer plus tard.",
    }
}
